@import "~@/styles/variables/variables.scss";


















































































































































































































































.use-gas-analysis {
	height: 100%;
	padding: 12px 24px;
	display: flex;
	flex-direction: column;
	.p-title {
		line-height: 48px;
		h3 {
			position: relative;
			padding-left: 12px;
			color: #3f435e;
			font-size: 18px;
			font-weight: 600;
			&:after {
				content: '';
				width: 4px;
				height: 16px;
				background-color: #4a69ff;
				position: absolute;
				left: 0;
				top: 17px;
			}
		}
	}
	.label-form {
		padding: 0 4px;
		&-item {
			display: flex;
			padding: 4px 0;
			&__title {
				color: #9a9cb0;
			}
			&__value {
				color: #5f627d;
				width: 0;
				flex: 1;
				text-align: right;
				line-height: 1.2;
			}
		}
	}
	.content {
		flex: 1;
		height: 0;
		display: flex;
		margin-top: 12px;
		.device-list {
			width: 18.75vw;
			height: 100%;
			padding: 12px;
			border: 1px solid #f1f1f1;
			border-radius: 4px;
			overflow-y: scroll;
			.p-title {
				h3 {
					font-size: 17px;
				}
			}
			.list {
				margin-top: 12px;
				&-item {
					background-color: #fbfcff;
					border-radius: 6px;
					border: 1px solid #f1f1f1;
					padding: 12px;
					position: relative;
					cursor: pointer;
					&.selected {
						background-color: #e8efff;
						border-color: #567ced;
						overflow: hidden;
						&:after {
							content: '';
							position: absolute;
							width: 0;
							height: 0;
							border-top: 12px solid #4f7aff;
							border-right: 12px solid #4f7aff;
							border-bottom: 12px solid transparent;
							border-left: 12px solid transparent;
							z-index: 9;
							right: 0;
							top: 0;
						}
						&:before {
							content: '';
							position: absolute;
							background-color: #fff;
							width: 16px;
							height: 16px;
							top: 0;
							right: 0;
							font-size: 12px;
							z-index: 10;
							clip-path: polygon(44% 55%, 79% 18%, 87% 24%, 44% 70%, 16% 38%, 23% 31%);
						}
					}
					&.can-click {
						cursor: pointer;
					}
					&:not(:last-child) {
						margin-bottom: 16px;
					}
					.title_n {
						display: flex;
						align-items: center;
						padding-right: 12px;
						.name {
							color: #4e4e4e;
							font-weight: 500;
							font-size: 16px;
							margin-left: 8px;
						}
						.tag {
							margin-left: auto;
							background-color: #16c972;
							color: #fff;
							font-size: 12px;
							border-radius: 12px;
							padding: 3px 8px 4px 8px;
							&.offline {
								background-color: #aaabbd;
							}
						}
					}
					.label-form {
						margin-top: 12px;
					}
				}
			}
		}
		.analysis-result {
			width: 0;
			flex: 1;
			margin-left: 12px;
			display: flex;
			flex-direction: column;
			.top-search {
				display: flex;
				.serach-ops {
					margin-left: 12px;
					@include base-button;
				}
			}
			.analysis-chart {
				height: 0;
				flex: 1;
				#analysis {
					height: 100%;
				}
			}
		}
	}
}
